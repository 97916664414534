export const fallbackLng = 'ro';
export const languages = [fallbackLng, 'en'];
export const defaultNS = 'common';

export function getOptions(lng: string = fallbackLng, ns: string | string[] = defaultNS): any {
    return {
        supportedLngs: languages,
        fallbackLng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
        returnNull: false,
    };
}
