import React, { ReactElement, useMemo } from 'react';

import formatElements from './formatElements';

export interface TranslationQuery {
    [name: string]: any;
}

export interface TransProps {
    t: (i18nKey: string, values?: TranslationQuery) => string;
    i18nKey: string;
    values?: TranslationQuery;
    components?: ReactElement[] | Record<string, ReactElement>;
}

export const TransComponents = {
    b: <b />,
    br: <br />,
    link: <a />,
};

/**
 * Translate transforming:
 * <0>This is an <1>example</1><0>
 * to -> <h1>This is an <b>example</b><h1>
 */
export function Trans({ t, i18nKey, values, components }: TransProps): any {
    /**
     * Memoize the transformation
     */
    const result = useMemo(() => {
        const text = t(i18nKey, values);

        if (!components || components.length === 0) return text;

        return formatElements(text, components);
    }, [t, i18nKey, components, values]) as string;

    return result;
}
