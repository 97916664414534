'use client';

import i18next from 'i18next';
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next';
import { getOptions } from './settings';

export { changeLanguage, loadNamespaces, getFixedT } from 'i18next';
export { Trans } from './Trans';

// on client side the normal singleton is ok
i18next.use(initReactI18next).init({
    ...getOptions(),
    returnNull: false,
    lng: undefined, // let detect the language on client side
    react: {
        useSuspense: false,
    },
});

export function useTranslation(lng: string | null, ns: string, options: any = {}) {
    if (lng && i18next.resolvedLanguage !== lng) i18next.changeLanguage(lng).catch(() => {});
    return useTranslationOrg(ns, options);
}

export default i18next;
//
// export interface TranslationQuery {
//     [name: string]: any;
// }
//
// export interface TransProps {
//     locale?: string;
//     i18nKey: string;
//     values?: TranslationQuery;
//     components?: ReactElement[] | Record<string, ReactElement>;
//     ns?: string;
//     options?: any;
// }
//
// /**
//  * Translate transforming:
//  * <0>This is an <1>example</1><0>
//  * to -> <h1>This is an <b>example</b><h1>
//  */
// export function Trans({ locale, i18nKey, values, components, ns }: TransProps): any {
//     const { t } = useTranslation(locale, ns);
//     /**
//      * Memoize the transformation
//      */
//     const result = useMemo(() => {
//         const text = t(i18nKey, values);
//
//         if (!components || components.length === 0) return text;
//
//         return formatElements(text, components);
//     }, [t, i18nKey, components]) as string;
//
//     return result;
// }
