'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import useGlobalState from './useGlobalState';
import { myAccountUrl } from 'src/services/route-utils';

export default function useUser({ redirectTo = '', redirectIfFound = false, redirectIfNotFound = false }) {
    const { user, setLoadUser, logout } = useGlobalState();
    const router = useRouter();

    useEffect(() => {
        // if no redirect needed, just return (example: already on /dashboard)
        // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
        if (!redirectTo || !user) return;

        if (
            // if redirectTo is set, redirect if the user was not found.
            (redirectTo && !redirectIfFound && !user?.id) ||
            // if redirectIfFound is also set, redirect if the user was found
            (redirectIfFound && user?.id)
        ) {
            router.push(redirectTo || myAccountUrl('/rezultate'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, redirectIfFound, redirectTo]);

    useEffect(() => {
        if (!redirectIfNotFound || user) return;

        setLoadUser().then((user) => {
            if (!user) {
                router.replace(myAccountUrl('/login'));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectIfNotFound, user]);

    return { user, setLoadUser, logout };
}
